import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import ImageHero from "../components/image-hero"
import CopyRightBG from '../components/copyright-bg'

import "../css/who-we-serve.css"

import YoastSeoParts from '../fragments/seo';

class WhoWeServeTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const {
      imageLeftCopy,
      imageLeftTitle,
      imageLeftImage,
      customerTypes,
      heroCopy,
      heroHeadline
    } = page.template.templateWhoWeServe;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <ImageHero image={page.heroImage} title={heroHeadline} copy={heroCopy} />

        <div className="who-we-serve-container">
          <CopyRightBG
            image={imageLeftImage}
            title={imageLeftTitle}
            copy={imageLeftCopy}
          />

          <div className="container customer-types-wrapper">
            <div className="grid smallGrid align-center">
              <div className="col-md-12 maincopy">
                <h2>Who We Serve</h2>
                <hr />
                <div className="customer-types">
                  {customerTypes && customerTypes.map(({ title, slug, ...type }) => (
                    <div key={title} className="customer-type-wrapper">
                      <div className="customer-type">
                        <h3 style={{ fontSize: '24px' }}>{title}</h3>
                        {type.cptWhoWeServe?.imageLeftExcerpt && (
                          <div dangerouslySetInnerHTML={{ __html: type.cptWhoWeServe?.imageLeftExcerpt }} />
                        )}
                        <Link to={`/who-we-serve/${slug}`}>Learn More</Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default WhoWeServeTemplate

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      heroImage: featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      template {
        ... on WpTemplate_WhoWeServe {
          templateName
          templateWhoWeServe {
            imageLeftCopy
            imageLeftTitle
            imageLeftImage {
              altText
              localFile {
                publicURL
              }
            }
            customerTypes {
              ... on WpWhoWeServe {
                id
                cptWhoWeServe {
                  imageLeftExcerpt
                }
                slug
                title
              }
            }
            heroCopy
            heroHeadline
          }
        }
      }
    }
  }
`