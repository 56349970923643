import React from "react"
import { graphql } from "gatsby"

import '../css/copyright-bg.css';

import background from "../images/country.jpg" 

const CopyRightBG = ({ image, title, copy, cta }) => {

  return (
    <div className="container copyRightBG" style={{'backgroundImage':'url('+background+')'}}>
      <div className="grid smallGrid align-center">
        <div className="col-md-6">
          { image && 
            <img src={image.localFile.publicURL} alt={image.altText} className="flex-img" />
          }
        </div>
        <div className="col-md-6 maincopy">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          {cta &&
            <a className="mainCTA" href={cta.url} target={cta.target}>{cta.title}</a>
           }
        </div>
      </div>
    </div>
  )
}

export default CopyRightBG